import React from "react";
import { Home } from "./Components";
import { Route, Routes } from "react-router-dom"; 
import Product_page from "./Components/product/Product_page";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
    <Navbar />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="product" element={<Product_page />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
