import React from "react";
import img from "../assets/about_img.png";
import Button from "../common/Button";
import Heading from "../common/Heading";

const About_Section = () => {
  return (
    <div id="about">
      <div className="container pt-20 flex md:flex-row flex-col items-center text-center md:text-left justify-center md:gap-10 gap-5">
        <div className="md:w-[40%]">
          <img src={img} alt="about" className="w-full md:max-w-md" />
        </div>
        <div className="md:w-1/2">
          <div className="mb-4 ">
            <Heading text="About Us" />
          </div>
          <p className="mb-4 text-justify">
            Biotreat is a sustainable brand that offers eco-friendly,
            compostable, and durable products designed for a cleaner tomorrow.
            Our products are crafted to make a positive impact on the
            environment. Be a part of a cleaner and greener future with Biotreat
            solutions.
          </p>
          <p className="mb-4 text-justify">
            We believe in the power of sustainable solutions and are committed
            to providing products that are environmentally friendly. Our
            products are made from natural materials and are designed to be
            compostable, reducing waste and promoting a cleaner environment.
          </p>
          <div>
            <Button text="Learn More" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_Section;
