import React from "react";
import GetInTouchButton from "../common/GetInTouchButton";
import { HiOutlineGlobeAlt, HiShoppingCart } from "react-icons/hi2";
import { IoIosArrowRoundUp } from "react-icons/io";
import { GiCoffeeCup, GiHamburger } from "react-icons/gi";
import { MdHotel, MdRamenDining } from "react-icons/md";
import { FaHotel } from "react-icons/fa";
const service = [
  {
    icon: <HiOutlineGlobeAlt  />,
    title: "Export",
    description:
      "Expand your reach with our export packaging solutions, designed for global markets.",
  },
  
 
  {
    icon:<FaHotel   />,
    title: "Hospitality",
    description:
      "Deliver unforgettable guest experiences with our premium packaging solutions.",
  },
  {
    icon: <GiHamburger  />,
    title: "Food Service",
    description:
      "Craft unique customer experiences with our custom packaging solutions, designed for your culinary creations.",
  },
  {
    icon: <MdRamenDining  />,
    title: "Food Processing",
    description:
      "Enhance your product’s shelf life and quality with our innovative packaging solutions.",
  },
  {
    icon: <HiShoppingCart  />,
    title: "Retail",
    description:
      "Boost your brand with our private label packaging, tailored to resonate with your customers.",
  },

];

const Service_Section = () => {
  return (
    <div className="pt-10 pb-20  bg-primary "  id="services">
      <div className="container flex flex-col md:flex-row gap-5 md:gap-7 md:justify-between justify-center h-fit ">
        {/* Add a wrapper with sufficient height */}
        <div className="md:sticky top-[20%] md:h-[100vh]   ">
          <div className="flex flex-col text-center md:text-left">
            <h2 className="text-lg lg:text-2xl font-normal tracking-widest text-transparent bg-clip-text text-white">
              CUSTOMERS WE SERVE
            </h2>
            <div className="flex flex-row md:flex-col gap-3 items-center md:items-start">
              <h2 className="text-3xl lg:text-7xl text-transparent bg-clip-text text-white md:w-[300px] w-full md:my-3 uppercase">
              Catering   TO EVERY NEED
              </h2>
              <div>
                <GetInTouchButton />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="h-[2px] bg-white rounded-full mb-6"></div>
          {service.map((item) => (
            <React.Fragment key={item.title}>
              <div className="flex flex-row justify-between md:gap-28 gap-10 mt-6 md:mt-8">
                <div className="mt-2 text-white md:text-3xl text-2xl">{item.icon}</div>
                <div className="flex flex-col gap-1 text-white text-start">
                  <h3 className="uppercase md:text-4xl sm:text-2xl text-xl">{item.title}</h3>
                  <p className="capitalize max-w-lg text-sm sm:text-[16px]">
                    {item.description}
                  </p>
                  <p className="flex gap-1 text-secondary items-center text-[14px] ">
                    Learn More{" "}
                    <IoIosArrowRoundUp className="md:text-2xl text-xl rotate-45 hover:rotate-90 transition-all hover:translate-x-1 delay-100" />
                  </p>
                </div>
              </div>
              <div className="h-[2px] bg-white rounded-full mt-6 md:mt-8"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service_Section;

