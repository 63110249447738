import React from 'react';
import { motion } from 'framer-motion';

const Button = ({text}) => {
  return (
    <motion.button
      className={`relative px-6 py-3 font-medium text-white bg-primary rounded-lg overflow-hidden group`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <span className="absolute inset-0 w-full h-full bg-secondary transform scale-x-0 group-hover:scale-x-100 transition-all origin-left"></span>
      <span className="relative z-10">{text}</span>
    </motion.button>
  );
};

export default Button;
