import React, { useState } from "react";
import banner from "../../assets/Product/banner-product.jpg";
import { IoIosArrowForward } from "react-icons/io";

import roundplate from "../../assets/Product/round plate/ROUND PLATE.png";
import roundplate2 from "../../assets/Product/round plate/plain.png";
import threecp_round from '../../assets/Product/round plate/3CP_Round_plate.png';
import fourcp_round from '../../assets/Product/round plate/4CP_ROUND_PLATE.png'; 
import sqarplate from '../../assets/Product/sqar plate/SQUARE PLATE.png'
import threecp_sqar from '../../assets/Product/sqar plate/3CP SQUARE PLATE.png'
import five_sqar from '../../assets/Product/sqar plate/5CP MEAL TRAY.png'
import five_sqar_large from '../../assets/Product/sqar plate/5CP MEAL TRAY large.png'
import six_sqar from '../../assets/Product/sqar plate/6.png'
import bowl from '../../assets/Product/bowl/BOWL.png'
import  bowlsqar from '../../assets/Product/bowl/DONA SQUARE BOWL.png'
import donabowl from '../../assets/Product/bowl/Dona.png'
import clamshall from '../../assets/Product/clamshell/Clamshell.png'
import nineClamshall from '../../assets/Product/clamshell/9x9 Clamshell.png'
import nineBysixClamshall from '../../assets/Product/clamshell/9x6 Clamshell.png'
import threeCpclamshall from '../../assets/Product/clamshell/8x3 CP CLAMSHELL.png'
import cup from '../../assets/Product/cup/cup.png'
import cuplid from '../../assets/Product/cup/cup-lid.png'
import fork from '../../assets/Product/fork/fork.png'
import knife from '../../assets/Product/fork/knif.png'
const ProductPage = () => {
  const categories = [
    "Round Plate",
    "Square Plate",
    "Meal Tray",
    "Bowl",
    "Clamshell",
    "Cup",
    // "Wooden Cutlery",
    "Knife & Fork"
  ];

  const allProducts = [
    { id: 1, name: `6" Round Plate`, image: roundplate, category: "Round Plate" },
    { id: 2, name: `7" Round Plate`, image: roundplate, category: "Round Plate" },
    { id: 3, name: `9" Round Plate`, image: roundplate, category: "Round Plate" },
    { id: 4, name: `10" Round Plate`, image: roundplate, category: "Round Plate" },
    { id: 5, name: `11" Round Plate`, image: roundplate2, category: "Round Plate" },
    { id: 6, name: `12" Round Plate`, image: roundplate2, category: "Round Plate" },
    { id: 7, name: `9"  3 CP ROUND PLATE`, image: threecp_round, category: "Round Plate" },
    { id: 8, name: `10" 3 CP ROUND PLATE`, image: threecp_round, category: "Round Plate" },
    { id: 9, name: `11" 4 CP ROUND PLATE`, image: fourcp_round, category: "Round Plate" },
    { id: 10, name: `12" 4 CP ROUND PLATE`, image: fourcp_round, category: "Round Plate" },
    { id: 11, name: `7" SQUARE PLATE`, image: sqarplate, category: "Square Plate" },
    { id: 12, name: `3 CP SQUARE MEAL TRAY`, image: threecp_sqar, category: "Meal Tray" },
    { id: 13, name: `5 CP MEAL TRAY SMALL`, image: five_sqar, category: "Meal Tray" },
    { id: 14, name: `5 CP MEAL TRAY BIG`, image: five_sqar_large, category: "Meal Tray" },
    { id: 15, name: `6 CP MEAL TRAY`, image: six_sqar, category: "Meal Tray" },
    { id: 16, name: `120ML ROUND BOWL`, image: bowl, category: "Bowl" },
    { id: 17, name: `180ML ROUND BOWL`, image: bowl, category: "Bowl" },
    { id: 18, name: `240ML ROUND BOWL`, image: bowl, category: "Bowl" },
    { id: 19, name: `360ML ROUND BOWL`, image: bowl, category: "Bowl" },
    { id: 20, name: `500ML SQUAR BOWL`, image: bowlsqar, category: "Bowl" },
    {id:21 , name: `4"X4" DONA BOWL`, image: donabowl, category: "Bowl" },
    {id:22 , name: `6 " CLAMSHELL`, image:clamshall , category: "Clamshell" },
    {id:23 , name: `8"  CLAMSHELL`, image:clamshall , category: "Clamshell" },
    {id:24 , name: `9" CLAMSHELL`, image:nineClamshall , category: "Clamshell" },
    {id:25 , name: `8" 3 CP CLAMSHELL`, image:threeCpclamshall , category: "Clamshell" },
    {id:26 , name: `9" 3 CP CLAMSHELL`, image:threeCpclamshall , category: "Clamshell" },
    {id:27 , name: `9" X 6" REACTANGLE CLAMSHELL`, image:nineBysixClamshall , category: "Clamshell" },
    {id:28 , name: `250 Ml DRINK CUP`, image:cup , category: "Cup" },
    {id:29 , name: `80 MM LID`, image:cuplid , category: "Cup" },
    {id:30 , name: `90 MM LID`, image:cuplid , category: "Cup" },
    {id:31 , name: `FORK`, image:fork , category: "Knife & Fork" },
    {id:32 , name: `KNIFE`, image:knife , category: "Knife & Fork" },
  ];

  const [active, setActive] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState(
    allProducts.filter((product) => product.category === categories[0])
  );

  const handleCategoryClick = (index) => {
    setActive(index);
    setFilteredProducts(
      allProducts.filter((product) => product.category === categories[index])
    );
  };

  return (
    <div>
      {/* Banner Section */}
      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50" />
        <img className="w-full h-96 object-cover" src={banner} alt="banner" />
        <div className="absolute inset-0 flex items-center md:justify-start justify-center ml-[20%]">
          <h1 className="text-white text-4xl md:text-6xl font-bold">
            Our Products
          </h1>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="mt-12 container mx-auto flex md:flex-row flex-col gap-8 py-5 items-start">
        {/* Sidebar */}
        <div className="w-full md:w-1/4 md:sticky top-20">
          <h2 className="text-2xl font-bold mb-4">Main Product</h2>
          <ul className="space-y-2 bg-white rounded-md border-gray-500 border-2 overflow-hidden ">
            {categories.map((category, index) => (
              <li
                key={index}
                onClick={() => handleCategoryClick(index)}
                className={`p-2 flex items-center gap-2 cursor-pointer ${
                  active === index ? "bg-primary text-white" : "hover:bg-secondary hover:text-white"
                }`}
              >
             <IoIosArrowForward />   {category}
                {active === index && (
                  <IoIosArrowForward className="ml-auto text-white" />
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Product Grid */}
        <div className="w-full md:w-3/4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-2">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-md rounded-lg overflow-hidden h-fit hover:translate-y-3 transition-all delay-300"
            >
              <img
                className="w-full h-48 object-contain p-4"
                src={product.image}
                alt={product.name}
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold">{product.name}</h3>
                <div className="mt-4 flex justify-between items-center">
                  <button className="text-primary font-medium flex items-center gap-1">
                    <span>🛒</span> Product Inquiry
                  </button>
                  <button className="text-primary font-medium flex items-center gap-1">
                    <span>🔗</span> Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
