import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

const InquiryForm = ({ togglePopup, isOpen }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    productName: "",
    number: "",
    address: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }
    return () => {
      document.body.style.overflow = ""; // Clean up on unmount
    };
  }, [isOpen]);


  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address.";
    }
    // if (!formData.productName.trim())
    //   newErrors.productName = "Product name is required.";
    // if (!formData.number.trim()) {
    //   newErrors.number = "Phone number is required.";
    // } else if (!/^\d{10}$/.test(formData.number)) {
    //   newErrors.number = "Phone number must be 10 digits.";
    // }
    // if (!formData.address.trim()) newErrors.address = "Address is required.";
    return newErrors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        productName: "",
        number: "",
        address: "",
      });
      setErrors({});
      togglePopup();
    }
  };

  const [isdropdownOpen, setisdropdownOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const countryCodes = [
    { code: "+1", label: "US" },
    { code: "+91", label: "In" },
    { code: "+44", label: "UK" },
    { code: "+61", label: "Aus" },
    { code: "+81", label: "JP" },
    { code: "+86", label: "CN" },
    { code: "+49", label: "DE" },
    { code: "+33", label: "FR" },
    { code: "+39", label: "IT" },
    { code: "+7", label: "RU" },
    { code: "+82", label: "KR" },
    { code: "+34", label: "ES" },
    { code: "+55", label: "BR" },
    { code: "+27", label: "ZA" },
    { code: "+971", label: "UAE" },
    { code: "+65", label: "SG" },
    { code: "+63", label: "PH" },
    { code: "+66", label: "TH" },
    { code: "+64", label: "NZ" },
    { code: "+41", label: "CH" },
    { code: "+31", label: "NL" },
    { code: "+46", label: "SE" },
    { code: "+20", label: "EG" },
    { code: "+98", label: "IR" },
    { code: "+92", label: "PK" },
    { code: "+60", label: "MY" },
    { code: "+94", label: "LK" },
    { code: "+212", label: "MA" },
    { code: "+52", label: "MX" },
    { code: "+880", label: "BD" },
    { code: "+972", label: "IL" },
    { code: "+62", label: "ID" },
  ];
  const handleSelect = (code) => {
    setCountryCode(code);
    setisdropdownOpen(false);
    setFormData((prev) => ({
      ...prev,
      number: `${code}${prev.number.replace(/^\+\d*/, "")}`,
    }));
  };
  return (
    <div className="relative">
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center md:pt-12 overflow-hidden px-2 z-50 ">
          <div className="bg-white rounded-lg shadow-lg p-6 relative w-96 xl:h-[calc(100%-60px)] 2xl:h-fit h-fit overflow-auto">
            <button
              onClick={togglePopup}
              className="absolute top-4 right-4 text-gray-500 hover:text-black"
            >
              <IoClose className="text-2xl" />
            </button>
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Inquiry Form
            </h2>
            <div className="flex flex-col md:flex-row w-full justify-between gap-8">
              {/* Right Section */}
              <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col gap-3">
                  {[
                    { id: "name", label: "Your Name", type: "text" },
                    { id: "email", label: "Email", type: "email" },
                    {
                      id: "productName",
                      label: "Product Name",
                      type: "text",
                    },
                  ].map(({ id, label, type }) => (
                    <div key={id}>
                      <label
                        htmlFor={id}
                        className="block text-sm font-medium text-gray-700 text-start"
                      >
                        {label}
                      </label>
                      <input
                        type={type}
                        id={id}
                        className={`w-full border ${
                          errors[id] ? "border-red-500" : "border-gray-300"
                        } rounded-md p-2`}
                        placeholder={`Enter your ${label.toLowerCase()}`}
                        value={formData[id]}
                        onChange={handleChange}
                      />
                      {errors[id] && (
                        <p className="text-red-500 text-sm text-start">
                          {errors[id]}
                        </p>
                      )}
                    </div>
                  ))}

                  <div>
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium text-gray-700 text-start"
                    >
                      Phone Number
                    </label>
                    <div className="flex gap-2">
                      <div className="relative">
                        <div
                          className="w-20 border border-gray-300 rounded-md p-2 cursor-pointer flex items-center justify-between"
                          onClick={() => setisdropdownOpen(!isdropdownOpen)}
                        >
                          {countryCode} <span className="text-gray-500">▼</span>
                        </div>
                        {isdropdownOpen && (
                          <div
                            className="absolute w-36 border border-gray-300 rounded-md bg-white max-h-52 md:max-h-48 overflow-y-auto z-50"
                          >
                            {countryCodes.map(({ code, label }) => (
                              <div
                                key={code}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleSelect(code)}
                              >
                                {code} ({label})
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <input
                        type="tel"
                        id="number"
                        className={`flex-grow border ${
                          errors.number ? "border-red-500" : "border-gray-300"
                        } rounded-md p-2`}
                        placeholder="Enter your phone number"
                        value={formData.number.replace(/^\+\d*/, "")}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.number && (
                      <p className="text-red-500 text-sm text-start">
                        {errors.number}
                      </p>
                    )}
                  </div>

                  {/* Address Field */}
                  <div>
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700 text-start"
                    >
                      Address
                    </label>
                    <textarea
                      id="address"
                      className={`w-full border ${
                        errors.address ? "border-red-500" : "border-gray-300"
                      } rounded-md p-2`}
                      placeholder="Enter your address"
                      rows="3"
                      value={formData.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <p className="text-red-500 text-sm text-start">
                        {errors.address}
                      </p>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full mt-6 bg-primary text-white px-4 py-2 rounded hover:bg-secondary"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InquiryForm;
