import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../common/Button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import img1 from "../assets/slider1.png";  // Replace with actual images if necessary

const sliderData = [
  {
    heading: "Welcome to Biotreat",
    subheading: "Discover eco-friendly, compostable, and durable products designed for a cleaner tomorrow.",
    bgColor: "bg-[#0B4F44]",
    image: img1,  
    buttoncolor:'bg-[#66BB94]'
  },
  {
    heading: "Sustainable Solutions",
    subheading: "Our products are crafted to make a positive impact on the environment.",
    bgColor: "bg-[#66BB94]",
    image: img1,
    buttoncolor:'bg-[#ced967]'

  },
  {
    heading: "Join the Movement",
    subheading: "Be a part of a cleaner and greener future with Biotreat solutions.",
    bgColor: "bg-[#ced967]",
    image: img1,
    buttoncolor:'bg-[#0B4F44]'

  },
];

const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatic slide transition
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % sliderData.length);
    }, 7000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % sliderData.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + sliderData.length) % sliderData.length);
  };

  return (
    <div className="relative w-full md:h-[550px] h-[400px] overflow-hidden" id="home">
      <AnimatePresence>
        {sliderData.map((slide, index) => {
          if (index !== currentSlide) return null;

          return (
            <motion.div
              key={index}
              className={`md:mt-12 absolute inset-0 p-8 flex flex-row md:justify-between justify-center items-center ${slide.bgColor}`}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}
            >
              <div className="flex flex-col md:items-start text-center md:text-left md:pl-28">
                <h1 className="text-white md:mt-0 mt-10">{slide.heading}</h1>
                <p className="text-white mt-4 md:w-[500px] w-[350px]">{slide.subheading}</p>
                {/* <div className={`mt-6 ${slide.buttoncolor} rounded-lg`}>
                  <Button text="Shop Now" />
                </div> */}
                <div className="mt-4">
                    <button className=" px-6 py-3 font-medium text-white rounded-lg border-2" >Shop Now</button>
                </div>
              </div>
              <div className="md:pr-28 hidden md:block">
                <img src={slide.image} alt="slider" className="md:w-[550px] w-[400px]" />
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>

      {/* Navigation buttons */}
      <button
        onClick={handlePrev}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white md:p-4 p-2 rounded-full shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 hidden sm:block"
        aria-label="Previous Slide"
      >
        <AiOutlineLeft className="md:text-[15px] " />
      </button>
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white md:p-4 p-2 rounded-full shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 hidden sm:block"
        aria-label="Next Slide"
      >
        <AiOutlineRight className="md:text-[15px] " />
      </button>
    </div>
  );
};

export default HeroSection;
