import React from 'react'
import Navbar from './Navbar'
import Hero_Section from './Hero_Section'
import About_Section from './About_Section'
import LifeCycle_Section from './LifeCycle_Section'
import Product_Section from './product/Product_Section'
import Difference_Section from './Difference_Section'
import Service_Section from './Service_Section'
import Testimonial_Section from './Testimonial_Section'
import Footer from './Footer'

export const Home = () => {
  return (
    <>
        <Hero_Section />
        <About_Section  />
        <LifeCycle_Section  />
        <Product_Section />
        <Difference_Section />
        <Service_Section />
        {/* <Testimonial_Section  /> */}
    </>
  )
}