import React, { useState } from "react";
import logo from "../assets/logo_png.png";
import { FaInstagram } from "react-icons/fa";
import { IoIosArrowRoundUp } from "react-icons/io";
import InquiryForm from "./Form";
import { Link } from "react-scroll";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <footer className="bg-secondary text-primary py-10" id="contact">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap md:flex-nowrap justify-between gap-10">
            {/* Left Section */}
            <div className="w-full md:w-1/3">
              <img src={logo} alt="logo" className="w-32" />
              <div className="mt-6 space-y-4">
                <div>
                  <p className="font-bold">Get in Touch</p>
                  <a
                    href="mailto:info@biotreat.in"
                    className="text-sm text-primary hover:underline"
                  >
                    info@biotreat.in
                  </a>
                </div>
                <div>
                  <p className="font-bold">Contact</p>
                  <a
                    href="tel:+91 96891 56781"
                    className="text-sm text-primary hover:underline"
                  >
                    +91 90335 54893
                  </a>
                </div>
                <div>
                  <p className="font-bold">Location</p>
                  <p className="text-sm text-primary">
                    303, Eden Ceramic City, 8-A National Highway, Morbi 363642,
                    Gujarat, India.
                  </p>
                </div>
              </div>
            </div>

            {/* Center Sections */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-10 w-full">
              {/* Product Section */}
              <div>
  <h3 className="text-lg font-bold uppercase">Product</h3>
  <div className="mt-4 space-y-2 flex flex-col">
    {[
      "Bowls",
      "Plates & Platters",
      "Trays",
      "Produce",
      "Clamshells",
    ].map((item) => (
      <li key={item} className="flex items-center">
        <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
        <Link to={"products"} smooth={true} duration={500}>
          <span className="text-sm text-primary hover:underline cursor-pointer">
            {item}
          </span>
        </Link>
      </li>
    ))}
  </div>
</div>


              {/* Service Section */}
              <div>
                <h3 className="text-lg font-bold uppercase">Services</h3>
                <div className="mt-4 space-y-2 flex flex-col">
                  {[
                    "Export",
                    "Hospitality",
                    "Food Service ",
                    "Food Processing",
                    "Retail",
                  ].map((item) => (
                    <li key={item} className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
                      <Link to={"services"} smooth={true} duration={500}>
                        <span className="text-sm text-primary hover:underline cursor-pointer">
                          {item}
                        </span>
                      </Link>
                    </li>
                  ))}
                </div>
              </div>

              {/* About Section */}
              <div className="">
                <h3 className="text-lg font-bold uppercase">Quick Links</h3>
                <div className="mt-4 space-y-2 flex-col flex">
                  <Link to={"Home"} smooth={true} duration={500}>
                    <li className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
                      <span className="text-sm text-primary hover:underline cursor-pointer">
                        Home
                      </span>
                    </li>
                  </Link>
                  <Link to={"about"} smooth={true} duration={500}>
                    <li className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
                      <span className="text-sm text-primary hover:underline cursor-pointer">
                        About Us
                      </span>
                    </li>
                  </Link>
                  <Link to={"products"} smooth={true} duration={500}>
                    <li className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
                      <span className="text-sm text-primary hover:underline cursor-pointer">
                        Products
                      </span>
                    </li>
                  </Link>
                  <Link to={"services"} smooth={true} duration={500}>
                    <li className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
                      <span className="text-sm text-primary hover:underline cursor-pointer">
                        Services
                      </span>
                    </li>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Social & Call-to-Action */}
          <div className="mt-10 flex flex-col items-center md:items-start space-y-6">
            <div className="bg-primary md:p-3 p-2 rounded-full cursor-pointer ">
              <FaInstagram className="md:text-2xl text-xl text-secondary " />
            </div>
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={togglePopup}
            >
              <p className="md:text-5xl text-3xl font-bold">Let’s Talk!</p>
              <IoIosArrowRoundUp className="md:text-5xl text-4xl font-bold rotate-45 hover:rotate-90 transition-transform" />
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-10 border-t border-gray-600 pt-6 flex flex-col md:flex-row justify-between items-center text-sm text-primary">
            <p>&copy; 2024 Biotreat. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a className="hover:underline">Terms of Use</a>
              <a className="hover:underline">Privacy Policy</a>
              <a href="#" className="hover:underline">
                Back to Top
              </a>
            </div>
          </div>
        </div>
      </footer>
      <InquiryForm togglePopup={togglePopup} isOpen={isOpen} />
    </>
  );
};

export default Footer;
