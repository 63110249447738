import React, { useState } from "react";
import { motion } from "framer-motion";
import { HiArrowUpRight } from "react-icons/hi2";
import InquiryForm from "../Components/Form";

const GetInTouchButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <div className="  h-fit" onClick={togglePopup}>
      <motion.div
        className="relative w-10 h-10 p-3 md:w-24 md:h-24 md:p-3  rounded-full flex items-center justify-center bg-white"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        whileHover={{ scale: 1.1, rotate: 5 }}
        whileTap={{ scale: 0.95 }}
        transition={{ type: "spring", stiffness: 200, damping: 10 }}
      >
        <HiArrowUpRight className="text-green-500 text-3xl md:text-5xl" />
        
      </motion.div>
    </div>
    <InquiryForm togglePopup={togglePopup} isOpen={isOpen} />
    
    </>
  );
};

export default GetInTouchButton;
