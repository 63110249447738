import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import product1 from "../../assets/Product/SQUARE PLATE.png";
import product2 from "../../assets/Product/3CP SQUARE PLATE.png";
import product3 from "../../assets/Product/4CP ROUND PLATE.png";
import product4 from "../../assets/Product/5CP MEAL TRAY.png";
import product5 from "../../assets/Product/750ML CONTAINER & LID.png";
import product6 from "../../assets/Product/CLAMSHELL (2).png";
import product7 from "../../assets/Product/DONA SQUARE BOWL.png";
import product8 from "../../assets/Product/BOWL.png";
import product9 from "../../assets/Product/ROUND PLATE.png";
import Heading from "../../common/Heading";

const products = [
  { id: 1, name: "1D Plate", image: product1, price: 10 },
  { id: 2, name: "3CP Square Plate", image: product2, price: 20 },
  { id: 3, name: `12" 4CP Round Plate`, image: product3, price: 30 },
  { id: 4, name: `5CP Meal Tray Plate `, image: product4, price: 40 },
  { id: 5, name: `750ML CONTAINER & LID`, image: product5, price: 50 },
  { id: 6, name: "6''x6'' CLAMSHELL", image: product6, price: 60 },
  { id: 7, name: "Dona", image: product7, price: 70 },
  { id: 8, name: "Large Bowl", image: product8, price: 80 },
  { id: 9, name: "Plain Plate", image: product9, price: 20 },
];

const ProductCard = ({ product, index, hoveredIndex, setHoveredIndex }) => {
  const navigate = useNavigate(); // Correct usage of useNavigate

  return (
    <motion.div
      className={`relative rounded-lg overflow-hidden shadow-lg bg-white border border-gray-200 transition-transform duration-300 cursor-pointer ${
        hoveredIndex !== null && hoveredIndex !== index
          ? "md:blur-sm scale-[0.95] md:opacity-50"
          : "scale-100 md:opacity-100"
      }`}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
      whileHover={{ scale: 1.05 }}
      id="products"
      onClick={() => navigate("/product")} // Corrected navigation logic
    >
      {/* Product Image */}
      <div className="relative h-48">
        <img
          src={product.image}
          alt={product.name}
          className="absolute inset-0 w-full h-full object-contain p-4"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-center mt-3">{product.name}</h3>
      </div>
      {/* Overlay for Hover */}
      <motion.div
        className={`absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center text-white text-xl font-bold transition-opacity duration-300 ${
          hoveredIndex === index ? "opacity-100" : "opacity-0"
        }`}
      >
        {product.name}
      </motion.div>
    </motion.div>
  );
};

const ProductSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="container mx-auto my-20">
      <div className="text-center mb-10">
        <Heading text={"Our Products"} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            product={product}
            index={index}
            hoveredIndex={hoveredIndex}
            setHoveredIndex={setHoveredIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
